import React, { useState } from "react";
import { useSwipeable } from "react-swipeable";

const FlipCard = ({ hungarian, serbian, onNext, onPrevious }) => {
    const [flipped, setFlipped] = useState(false);
    const [animationState, setAnimationState] = useState(""); // Tracks the animation state ('left', 'right', or '')

    // Handle swipe actions
    const handleSwipe = (direction) => {
        setAnimationState(direction); // Trigger exit animation
        setTimeout(() => {
            setFlipped(false);
            if (direction === "left") onNext();
            if (direction === "right") onPrevious();
            setAnimationState(""); // Reset for entering animation
        }, 300); // Match the animation duration
    };

    const handlers = useSwipeable({
        onSwipedLeft: () => handleSwipe("left"),
        onSwipedRight: () => handleSwipe("right"),
        trackMouse: true, // Enable dragging with a mouse
    });

    return (
        <div
            {...handlers}
            className="flex flex-col items-center justify-center space-y-6 mt-6"
        >
            {/* Flip Card */}
            <div
                onClick={() => setFlipped(!flipped)}
                className={`relative w-80 h-48 bg-white rounded-lg shadow-lg cursor-pointer flex items-center justify-center text-xl font-semibold text-gray-800 transition-transform transform ${
                    animationState === "left" ? "animate-exit-left" : ""
                } ${animationState === "right" ? "animate-exit-right" : ""} ${
                    animationState === "" ? "animate-enter" : ""
                }`}
                style={{
                    perspective: "1000px",
                }}
            >
                <div
                    className={`absolute inset-0 flex items-center justify-center transition-transform transform ${
                        flipped ? "rotate-y-180" : ""
                    }`}
                    style={{
                        transformStyle: "preserve-3d",
                        backfaceVisibility: "hidden",
                    }}
                >
                    {flipped ? serbian : hungarian}
                </div>
            </div>

            {/* Bottom Navigation Buttons */}
            <div className="flex justify-between w-full max-w-xs mt-4 space-x-4">
                <button
                    onClick={() => handleSwipe("right")}
                    className="bg-blue-500 text-white px-4 py-2 rounded-lg shadow-md hover:bg-blue-600 transition"
                >
                    Previous
                </button>
                <button
                    onClick={() => handleSwipe("left")}
                    className="bg-blue-500 text-white px-4 py-2 rounded-lg shadow-md hover:bg-blue-600 transition"
                >
                    Next
                </button>
            </div>
        </div>
    );
};

export default FlipCard;
