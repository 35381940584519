import React, { useState, useEffect } from "react";
import Select from "react-select";
import Papa from "papaparse";
import FlipCard from "./FlipCard";

const App = () => {
    const [lessons, setLessons] = useState([]);
    const [selectedLesson, setSelectedLesson] = useState(null);
    const [words, setWords] = useState([]);
    const [currentWordIndex, setCurrentWordIndex] = useState(0);

    useEffect(() => {
        const lessonCount = 37;
        const options = Array.from({ length: lessonCount }, (_, i) => ({
            value: i + 1,
            label: `Lecke ${i + 1}`,
        }));
        setLessons(options);
    }, []);

    const handleLessonChange = (selectedOption) => {
        setSelectedLesson(selectedOption);
        const lessonFile = `/lessons/lecke_${selectedOption.value}.csv`;
        Papa.parse(lessonFile, {
            download: true,
            header: true,
            complete: (result) => {
                setWords(shuffleArray(result.data));
                setCurrentWordIndex(0);
            },
        });
    };

    const shuffleArray = (array) => array.sort(() => Math.random() - 0.5);

    const handleNextWord = () =>
        setCurrentWordIndex((prev) => (prev + 1) % words.length);

    const handlePreviousWord = () =>
        setCurrentWordIndex((prev) => (prev - 1 + words.length) % words.length);

    return (
        <div className="min-h-screen bg-gray-100 flex flex-col items-center p-6">
            <h1 className="text-3xl font-bold text-blue-600 mb-6">
                Flipcards Learning App
            </h1>
            <div className="w-full max-w-md">
                <Select
                    options={lessons}
                    value={selectedLesson}
                    onChange={handleLessonChange}
                    placeholder="Select a Lesson"
                    className="mb-6"
                />
            </div>
            {words.length > 0 && (
                <FlipCard
                    hungarian={words[currentWordIndex].hungarian}
                    serbian={words[currentWordIndex].serbian}
                    onNext={handleNextWord}
                    onPrevious={handlePreviousWord}
                />
            )}
        </div>
    );
};

export default App;
